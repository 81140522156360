/* Dashboard.css */

/* Wrapper for the entire dashboard */
.dashboard-wrapper {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* TOP BAR: Ticker input & tags row */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 1000;
  /* 1) On mobile, we want them stacked */
}

.add-ticker {
  display: flex;
  gap: 8px;
  /* optionally add margin-bottom in all views if you want more space always */
}

.add-ticker input {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-ticker button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-ticker button:hover {
  background-color: #0056b3;
}

/* Ticker Tags (list of added tickers) */
.ticker-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* 2) On mobile, give extra top margin for better separation from input */
}

.ticker-tag {
  display: flex;
  align-items: center;
  background-color: #e9ecef;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.remove-ticker {
  margin-left: 5px;
  background: none;
  border: none;
  color: red;
  font-size: 14px;
  cursor: pointer;
}
.remove-ticker:hover {
  color: darkred;
}

/* Row for date range & timeframe */
.date-range-row {
  display: flex;
  gap: 1rem;
  margin-top: 10px;
  align-items: center;
  padding: 0 10px; /* optional for consistent left/right padding */
}

.date-range-row label {
  display: flex;
  align-items: center;
  font-weight: 500;
}

/* Main charts section */
.charts-section {
  padding: 20px;
  margin-top: 10px;
}

/* Grid of ticker cards (2 columns on desktop, 1 column on narrow screens) */
.charts-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* Ticker card: container for chart + title, etc. */
.ticker-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  gap: 10px; /* space between <h2> and chart-block */
}

.ticker-card h2 {
  margin: 0;
}

/* The container that holds main chart (80%) and RSI chart (20%) */
.chart-block {
  display: flex;
  flex-direction: column; 
  width: 100%;
}

.main-chart-flex {
  flex: 0 0 80%;
  margin-bottom: 10px;
  position: relative;
}

.rsi-chart-flex {
  flex: 0 0 20%;
  position: relative;
}

/* Error message below charts */
.error-message {
  margin: 20px;
  color: red;
  text-align: center;
}

/* POPUP overlay for Yahoo Finance errors */
.popup-overlay {
  position: fixed;
  top: 0; 
  left: 0;
  width: 100%; 
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex; 
  align-items: center; 
  justify-content: center;
  z-index: 9999;
}

.popup-content {
  background: #fff;
  padding: 2rem;
  border-radius: 4px;
}

/* =========== Mobile Styles =========== */
@media (max-width: 767px) {
  /* Make sure page is not zoomed in */
  /* (This relies on having <meta name="viewport" content="width=device-width, initial-scale=1.0"> in your HTML) */

  /* Stack the "Add Ticker" and "Ticker Tags" */
  .top-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  /* Add some vertical space between input & ticker tags container */
  .ticker-tags {
    margin-top: 8px;
  }

  /* Single column for charts */
  .charts-container {
    grid-template-columns: 1fr;
  }

  /* Let date-range-row wrap onto multiple lines */
  .date-range-row {
    flex-wrap: wrap;
  }
  .date-range-row label {
    margin-bottom: 0.5rem; /* Some spacing for each label in wrap */
  }

  /* If you want Start/End on one line and Timeframe below:
     you can do something like giving timeframe a full width. */
  .date-range-row select {
    min-width: 120px; /* or 100% if you want it to fill a line */
  }
}
