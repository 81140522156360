/* Login.css */

/* 
  1) Basic container styling for overall centering
  of the form in the middle of the screen
*/
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Adjust these if you want full-screen centering: */
    min-height: 80vh;
    padding: 1rem;
  }
  
  /* 
    2) The actual form layout 
       - we can add a subtle border, padding, 
         and a max-width for responsiveness
  */
  .login-form {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9; 
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 2rem;
    max-width: 400px; /* ensures the form doesn’t get too wide on large screens */
    width: 100%;      /* let it shrink on mobile */
  }
  
  /* The login title heading */
  .login-title {
    margin-bottom: 1.5rem;
  }
  
  /* 
    3) Inputs styling
  */
  .login-input {
    margin-bottom: 1rem;
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* The submit button styling */
  .login-button {
    padding: 0.8rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #282c34;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #3d3f4a;
  }
  
  /* 
    4) Media query for smaller screens 
       - adjust as needed
  */
  @media (max-width: 480px) {
    .login-form {
      padding: 1rem;
      margin: 1rem;
    }
  
    .login-title {
      font-size: 1.2rem;
    }
  
    .login-input,
    .login-button {
      font-size: 0.9rem;
      padding: 0.6rem;
    }
  }
  