/* RSIChart.css */

.rsi-chart-wrapper {
  width: 100%;
  height: 100%; /* fill the parent flex item (20% of chart-block) */
  background-color: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
}

.Plotly,
.plotly-graph-div {
  background-color: #1e1e1e;
  color: #ffffff;
}

.plotly-axis-title,
.plotly-axis,
.plotly-grid {
  color: #ffffff !important;
  border-color: #444444 !important;
}
