/* Home.css */

/* 
  1) Container styling for the home page
     - centers text, adds padding, etc.
*/
.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    text-align: center;
    min-height: 80vh; /* adjust as needed */
    padding: 2rem;
    background-color: #f5f5f5; /* a subtle background if you like */
  }
  
  /*
    2) Heading and paragraph spacing
  */
  .home-page h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  
  .home-page p {
    margin-bottom: 2rem;
    font-size: 1.125rem;
    max-width: 600px; /* prevent very wide text on large screens */
  }
  
  /* 
    3) Button styling
  */
  .home-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #282c34;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .home-button:hover {
    background-color: #3d3f4a;
  }
  
  /*
    4) Responsive adjustments for smaller screens
       - we can reduce padding and font sizes if needed
  */
  @media (max-width: 480px) {
    .home-page {
      padding: 1rem;
    }
  
    .home-page h1 {
      font-size: 1.5rem;
    }
  
    .home-page p {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
  
    .home-button {
      font-size: 0.9rem;
      padding: 0.6rem 1rem;
    }
  }
  