/* App.css */

/* Core App styling */
.App {
  text-align: center;
}

/* Mobile-specific class (used if isMobile === true) */
.App-mobile {
  /* e.g. custom overrides for mobile-only, if desired */
}

/* Example of your spinning logo styles (if you still have a logo) */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navigation bar styling */
.nav-bar {
  /* Always keep a single row, even on mobile */
  display: flex;
  justify-content: center;
  gap: 10px; /* Reduced gap from 20px to 10px */
  background-color: #282c34;
  padding: 8px 0; /* Slightly thinner padding (was 10px 0) */
  margin-bottom: 20px;
}

/* We remove the media query that stacked them vertically
   so they stay on the same row at all screen sizes. */

/* Nav button styling */
.nav-button {
  text-decoration: none;
  color: #ffffff;
  background-color: #1a1a1a;
  padding: 8px 16px; /* Thinner than 10px 20px */
  border: 2px solid transparent;
  border-radius: 4px; /* Slightly smaller radius than 5px if you like */
  font-size: 14px;    /* Smaller font size than 16px */
  font-weight: bold;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background-color: #ffffff;
  color: #282c34;
  border-color: #ffffff;
  cursor: pointer;
}

.nav-button:active {
  transform: scale(0.95);
}
